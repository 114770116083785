import React, { useState, useMemo } from "react";
import { graphql } from "gatsby";
import withPreview from "../../utility/with-preview";
import classNames from "classnames";
import SingleCardProduct from "./single-card-product";
import Slider from "react-slick";
import { PrevArrow, NextArrow } from "../elements/arrows";

const CardProdotto = ({ data }) => {
  const { moduleTitle, whiteBg, firstTab, secondTab, card } = data;

  const slidesFirstTab = useMemo(
    () =>
      firstTab?.value &&
      card?.map((item) => item.content).filter((item) => item?.productType?.value === "firstTab"),
    [firstTab, card]
  );

  const slidesSecondTab = useMemo(
    () =>
      secondTab?.value &&
      card?.map((item) => item.content).filter((item) => item?.productType?.value === "secondTab"),
    [secondTab, card]
  );

  const sliders = [slidesFirstTab, slidesSecondTab].filter(Boolean);

  const tabs = useMemo(() => {
    const labels = [firstTab?.value, secondTab?.value].filter(Boolean);
    return labels.map((item) => ({
      value: item,
    }));
  }, [firstTab, secondTab]);

  const [selectedCard, setSelectedCard] = useState(0);

  var settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    arrows: true,
    autoplay: false,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    dots: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "10%",
          arrows: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "7%",
          arrows: false,
        },
      },
    ],
    appendDots: (dots) => (
      <div>
        <ul> {dots} </ul>
      </div>
    ),
    customPaging: () => (
      <div
        className={classNames("slick-dots__custom-dot", {
          "slick-dots__custom-dot--no-image": whiteBg?.value,
        })}
      ></div>
    ),
  };

  return (
    <section
      className={classNames("section overflow-hidden card-prodotto", {
        "card-prodotto--no-image": whiteBg?.value,
      })}
    >
      <div
        className={classNames("card-prodotto__bg-forms", {
          "card-prodotto__bg-forms--no-image": whiteBg?.value,
        })}
      >
        <div className="wrapper-container">
          <div className="container-fluid">
            {moduleTitle?.value && (
              <div
                className={classNames("card-prodotto__module-title", {
                  "card-prodotto__module-title--no-image": whiteBg?.value,
                })}
              >
                {moduleTitle.value}
              </div>
            )}
            {tabs.length > 1 && (
              <div
                className={classNames("card-prodotto__switch", {
                  "card-prodotto__switch--no-image": whiteBg?.value,
                })}
              >
                <button
                  onClick={() => setSelectedCard(0)}
                  name={0}
                  className={classNames("card-prodotto__switch-button", {
                    "card-prodotto__switch-button--no-border": whiteBg?.value,
                    "card-prodotto__switch-button--no-bg-color":
                      whiteBg?.value && selectedCard === 1,
                    "card-prodotto__switch-button--active": selectedCard === 0,
                  })}
                >
                  {firstTab.value}
                </button>
                <button
                  onClick={() => setSelectedCard(1)}
                  name={1}
                  className={classNames("card-prodotto__switch-button", {
                    "card-prodotto__switch-button--no-border": whiteBg?.value,
                    "card-prodotto__switch-button--no-bg-color":
                      whiteBg?.value && selectedCard === 0,
                    "card-prodotto__switch-button--active": selectedCard === 1,
                  })}
                >
                  {secondTab.value}
                </button>
              </div>
            )}
            <div className="row px-md-3">
              <div className="col-12 card-prodotto__cards-container">
                {sliders.map((slides, i) => (
                  <Slider
                    className={classNames("", {
                      "d-none": i !== selectedCard,
                    })}
                    {...settings}
                    key={i}
                  >
                    {slides.map((slide, j) => (
                      <div key={j}>
                        <SingleCardProduct item={slide} noImage={whiteBg?.value} />
                      </div>
                    ))}
                  </Slider>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withPreview(CardProdotto);
export const fragment = graphql`
  fragment JskCardProdottoFragment on LiferayJskCardProdotto {
    liferayFields {
      siteId
      articleId
    }
    moduleTitle {
      value
    }
    whiteBg {
      value
    }
    firstTab {
      value
    }
    secondTab {
      value
    }
    card {
      content {
        productType {
          value
        }
        productImage {
          node {
            gatsbyImageData(width: 491)
          }
          value {
            description
          }
        }
        productName {
          value
        }
        labelText {
          value
          content {
            labelType {
              value
            }
            labelColor {
              value
            }
            tooltipText {
              value
            }
          }
        }
        primaryFeatures {
          value
        }
        secondaryFeatures {
          value
        }
        modalText {
          value
        }
        discountLabel {
          value
        }
        cta {
          value
          content {
            ctaLink {
              value
            }
          }
        }
        productPrice {
          value
          content {
            crossedPrice {
              value
            }
            showFinancing {
              value
              content {
                monthlyPrice {
                  value
                }
                numberOfMonths {
                  value
                }
                tooltip {
                  value
                }
                taeg {
                  value
                }
                tan {
                  value
                }
              }
            }
          }
        }
      }
    }
  }
`;
