import React, { Fragment, useState } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Cta from "../elements/cta";
import Icon from "../elements/icon-svg";
import classNames from "classnames";
import Loadable from "@loadable/component";

const Modal = Loadable(() => import("react-overlays/Modal"));

const SingleCardProduct = ({ item, noImage }) => {
  const [warningModalVisible, setwarningModalVisible] = useState(false);

  const closeWarningModal = () => setwarningModalVisible(false);

  const renderBackdrop = (props) => (
    <button
      className="modale modale__backdrop"
      open={warningModalVisible}
      onClick={() => {
        setwarningModalVisible(false);
      }}
      {...props}
    />
  );

  return (
    <section
      className={classNames("card-prodotto__card", { "card-prodotto__card--no-image": noImage })}
    >
      {item?.productImage?.node && (
        <div className="card-prodotto__card-image">
          <GatsbyImage
            image={getImage(item.productImage.node)}
            alt={item.productImage.value?.description || ""}
          />
        </div>
      )}
      {item?.labelText && item?.labelText?.length > 0 && (
        <div className="card-prodotto__label-container">
          {item.labelText.map((label, i) => (
            <Fragment key={i}>
              {i < 3 && label?.value && (
                <div
                  className="card-prodotto__label"
                  style={
                    label?.content?.labelType?.value === "primary"
                      ? { backgroundColor: label?.content?.labelColor?.value, color: "#ffffff" }
                      : {
                          backgroundColor: "#ffffff",
                          color: label?.content?.labelColor?.value,
                          border: `1px solid ${label?.content?.labelColor?.value}`,
                        }
                  }
                >
                  {label.value}
                  {label?.content?.tooltipText?.value && (
                    <div className="card-prodotto__tooltip active d-none d-md-block">
                      <span
                        className="card-prodotto__tooltip-label"
                        style={
                          label?.content?.labelType?.value === "primary"
                            ? {
                                backgroundColor: "#ffffff",
                                color: label?.content?.labelColor?.value,
                              }
                            : {
                                backgroundColor: label?.content?.labelColor?.value,
                                color: "#ffffff",
                                borderRadius: "50%",
                                border: `1px solid ${label?.content?.labelColor?.value}`,
                              }
                        }
                      >
                        i
                      </span>
                      <div className="card-prodotto__tooltip-text">
                        {label.content.tooltipText.value}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </Fragment>
          ))}
        </div>
      )}
      <div className="card-prodotto__card-info">
        {item?.productName?.value && (
          <div className="card-prodotto__product-title">{item.productName.value}</div>
        )}
        {item?.primaryFeatures?.value && (
          <div
            className="card-prodotto__main-char"
            dangerouslySetInnerHTML={{ __html: item.primaryFeatures.value }}
          />
        )}
        {item?.modalText?.value && (
          <button
            className="card-prodotto__open-modal"
            onClick={() => setwarningModalVisible(true)}
          >
            <span>{item.modalText.value}</span>
            <Icon name={"plus-circle"} cls={"card-prodotto__plus-icon"} />
          </button>
        )}
        <div
          className={classNames("card-prodotto__separator", {
            "card-prodotto__separator--show-financing":
              item?.productPrice?.content?.showFinancing?.value === true,
            "card-prodotto__separator--no-modal-button": !item?.modalText?.value,
          })}
        ></div>
        {item?.productPrice?.content?.showFinancing?.value === false &&
          (item?.productPrice?.content?.crossedPrice?.value ||
            item?.discountLabel?.value ||
            item?.productPrice?.value) && (
            <>
              <div className="card-prodotto__price-line">
                {item?.productPrice?.content?.crossedPrice?.value && (
                  <span className="card-prodotto__crossed-price">{`${item.productPrice.content.crossedPrice.value}€`}</span>
                )}
                {item?.discountLabel?.value && (
                  <div className="card-prodotto__discount">{item.discountLabel.value}</div>
                )}
              </div>
              {item?.productPrice?.value && (
                <div className="card-prodotto__price">
                  <span>da </span>
                  <span className="card-prodotto__price card-prodotto__price--euro">
                    {`${item.productPrice.value}€`}
                  </span>
                </div>
              )}
            </>
          )}
        {item?.productPrice?.content?.showFinancing?.value === true && (
          <>
            <div className="card-prodotto__financing">
              <div className="card-prodotto__monthly-financing">
                <div>
                  <span>da</span>
                  <span className="card-prodotto__monthly-financing--price">{` ${item.productPrice.content.showFinancing.content.monthlyPrice.value}€ `}</span>
                  <span>/mese</span>
                </div>
                <div className="card-prodotto__financing-row">
                  <span>{` per ${item.productPrice.content.showFinancing.content.numberOfMonths.value} mesi`}</span>
                  <div className="card-prodotto__tooltip active d-none d-md-block">
                    <span className="card-prodotto__tooltip-label">i</span>
                    <div className="card-prodotto__tooltip-text card-prodotto__tooltip-text--financing">
                      {item.productPrice.content.showFinancing.content.tooltip.value}
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-prodotto__price-separator"></div>
              <div className="card-prodotto__taxes">
                <span className="card-prodotto__taeg">
                  {`TAEG ${item.productPrice.content.showFinancing.content.taeg.value}%`}
                </span>
                <span className="card-prodotto__tan">
                  {`TAN fisso ${item.productPrice.content.showFinancing.content.tan.value}%`}
                </span>
              </div>
            </div>
            {(item?.productPrice?.value || item?.productPrice?.content?.crossedPrice?.value) && (
              <div className="card-prodotto__price-financing">
                <span>{`oppure da ${item.productPrice.value}€ `}</span>
                <span className="card-prodotto__price-financing--crossed">{`invece di ${item.productPrice.content.crossedPrice.value}€`}</span>
              </div>
            )}
          </>
        )}
        <Cta cta={item?.cta} className="card-prodotto__cta" />
      </div>
      <Modal
        show={warningModalVisible}
        onHide={closeWarningModal}
        onClick={closeWarningModal}
        className="modale-wrapper"
        renderBackdrop={renderBackdrop}
      >
        <div className="modale card-prodotto__modale">
          <div className={"modale__content"}>
            <button
              className="modale__close-btn card-prodotto__modale-close-btn"
              onClick={closeWarningModal}
            >
              X
            </button>
            <div className="d-flex flex-column flex-lg-row">
              <div className="modale__paragraph d-flex flex-column">
                {item?.productName?.value && (
                  <h2 className="modale__paragraph-title text-left font-weight-bold card-prodotto__product-title">
                    {item.productName.value}
                  </h2>
                )}
                <div className="modale__paragraph-content">
                  {item?.primaryFeatures?.value && (
                    <div
                      className="card-prodotto__main-char"
                      dangerouslySetInnerHTML={{ __html: item.primaryFeatures.value }}
                    />
                  )}
                </div>
                <div className="card-prodotto__modale-separator"></div>
                <div className="modale__paragraph-content">
                  {item?.secondaryFeatures?.value && (
                    <div
                      className="card-prodotto__main-char"
                      dangerouslySetInnerHTML={{ __html: item.secondaryFeatures.value }}
                    />
                  )}
                </div>
                <Cta cta={item?.cta} className="card-prodotto__cta card-prodotto__modale-cta" />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </section>
  );
};

export default SingleCardProduct;
