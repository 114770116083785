import React, { useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import FasciaPaginaProdotto from "../components/page-content/fascia-pagina-prodotto";
import withPreview from "../utility/with-preview";
import useInitDataLayer from "../utility/useInitDataLayer";
import { useHandleRecaptchaBadge } from "../utility/useHandleRecaptchaBadge";
import { dataLayerPush, dataLayerStr2Obj } from "../utility/dataLayerUtils";

const PaginaProdottoTemplate = ({ data, location }) => {
  const {
    title,
    headerType,
    footerType,
    showSmartBannerApp,
    link,
    seo,
    fasce,
    impressionDetail,
    disableVwoAbTesting,
    recensioniVerificateId,
    gaEvent,
  } = data.page;
  useInitDataLayer(location, impressionDetail);
  useHandleRecaptchaBadge(fasce);

  //GA4
  useEffect(() => {
    if (gaEvent) {
      dataLayerPush(dataLayerStr2Obj('{"ecommerce":null}'));
      dataLayerPush(dataLayerStr2Obj(gaEvent.value));
    }
  }, [gaEvent]);
  //GA4

  return (
    <Layout
      headerType={headerType?.value?.[0]}
      footerType={footerType?.value?.[0]}
      location={location}
      recensioniVerificateId={recensioniVerificateId}
    >
      <Seo
        title={seo?.content?.htmlTitle?.value || title?.value}
        description={seo?.content?.metaDescription?.value}
        ogImage={seo?.content?.ogImage?.node}
        banner={showSmartBannerApp?.value}
        path={link}
        robots={seo?.content?.metaRobots?.value?.[0]}
        disableVwoAbTesting={disableVwoAbTesting?.value}
      />
      <div>
        {fasce?.map((fascia, i) => (
          <FasciaPaginaProdotto
            fascia={fascia.value}
            key={i}
            location={location}
            title={seo?.content?.htmlTitle?.value || title?.value}
          />
        ))}
      </div>
    </Layout>
  );
};

export default withPreview(PaginaProdottoTemplate, {
  subField: "page",
  fixed: true,
});
export const query = graphql`
  query ($id: String) {
    page: liferayJskLayoutPaginaProdotto(id: { eq: $id }) {
      liferayFields {
        siteId
        articleId
      }
      headerType {
        value
      }
      footerType {
        value
      }
      impressionDetail {
        value
      }
      gaEvent {
        value
      }
      link
      seo {
        content {
          htmlTitle {
            value
          }
          metaDescription {
            value
          }
          ogImage {
            node {
              resize(width: 1200) {
                src
                width
                height
              }
            }
          }
          metaRobots {
            value
          }
        }
      }
      title {
        value
      }
      disableVwoAbTesting {
        value
      }
      recensioniVerificateId {
        value
        content {
          recensioniVerificateOn {
            value
          }
        }
      }
      fasce {
        value {
          ...FasciaPaginaProdottoFragment
        }
      }
    }
  }
`;
