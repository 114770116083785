import React, { useState } from "react";
import { graphql } from "gatsby";
import classNames from "classnames";
import withPreview from "../../utility/with-preview";
import NavTabs from "../elements/nav-tabs";
import ComparatoreCardMono from "../elements/card-comparatore-mono";

const WidgetComparatorePrezzi = ({ data }) => {
  const { titoloComparatore, offertaTitle } = data;

  const [chosenProd, setChosenProd] = useState(0);
  const [selectActive, setSelectActive] = useState(true);

  return (
    <section className={classNames("section widget-comparatore-prezzi wrapper-container", {})}>
      {titoloComparatore?.value && (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2
                className={classNames(
                  "section__title widget-comparatore-prezzi__title",
                  titoloComparatore?.content?.titlePosition?.value?.[0]
                )}
                style={{ color: titoloComparatore?.content?.titleColor?.value }}
              >
                {titoloComparatore.value}
              </h2>
            </div>
          </div>
        </div>
      )}
      {offertaTitle?.length > 1 && (
        <NavTabs
          tabs={offertaTitle?.map((item) => item)}
          selectedTab={chosenProd}
          setTab={setChosenProd}
          className="d-xl-none pb-3"
        />
      )}
      {offertaTitle?.length > 1 && (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <ComparatoreCardMono
                products={offertaTitle}
                chosenProd={chosenProd}
                setChosenProd={setChosenProd}
                selectActive={selectActive}
                setSelectActive={setSelectActive}
                excludeNavTabs={true}
              />
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default withPreview(WidgetComparatorePrezzi);
export const fragment = graphql`
  fragment WidgetComparatorePrezziFragment on LiferayWidgetComparatorePrezzi {
    liferayFields {
      siteId
      articleId
    }
    infoTitle {
      value
      content {
        infoImg {
          node {
            contentUrl
          }
        }
      }
    }
    note {
      value
    }
    offertaTitle {
      value
      content {
        btnText {
          value
          content {
            btnDatalayer {
              value
            }
            btnLink {
              value
            }
          }
        }
        crmProdId {
          value
        }
        offertaIcona {
          node {
            gatsbyImageData(formats: PNG, width: 150)
          }
        }
        offertaLabel {
          value
          content {
            offertaLabelColor {
              value
            }
          }
        }
        offertaSwitchButtonTitle {
          value
          content {
            coloreBordoBottone {
              value
            }
            coloreBottone1 {
              value
            }
            coloreBottone2 {
              value
            }
          }
        }
        switchPresent {
          value
          content {
            switchTitle2 {
              content {
                caratt2 {
                  content {
                    val2Commodity {
                      value
                    }
                    val2 {
                      value
                    }
                    isTooltip2 {
                      content {
                        carattTooltipSw2 {
                          value
                        }
                      }
                      value
                    }
                    isBold2 {
                      value
                    }
                    hrSw2 {
                      value
                    }
                  }
                  value
                }
              }
              value
            }
          }
        }
        switchTitle1 {
          value
          content {
            caratt1 {
              value
              content {
                hrSw1 {
                  value
                }
                isBold1 {
                  value
                }
                isTooltip1 {
                  content {
                    carattTooltipSw1 {
                      value
                    }
                  }
                  value
                }
                val1 {
                  value
                }
                val1Commodity {
                  value
                }
              }
            }
            firstTooltip {
              value
            }
          }
        }
      }
    }
    showRecensioni {
      value
    }
    showTooltip {
      value
    }
    tableBtn {
      value
      content {
        tabBtnLink {
          value
        }
        tabBtnDatalayer {
          value
        }
      }
    }
    tableTitle {
      value
    }
    tableCaratt {
      content {
        tableVal {
          content {
            tableImg {
              node {
                contentUrl
              }
            }
          }
          value
        }
      }
      value
    }
    titoloComparatore {
      value
      content {
        titleColor {
          value
        }
        titlePosition {
          value
        }
      }
    }
  }
`;
