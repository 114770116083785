import React, { useState } from "react";
import { graphql } from "gatsby";
import classNames from "classnames";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import withPreview from "../../utility/with-preview";

const DettaglioProdotto = ({ data }) => {
  const [open, setOpen] = useState(false);
  const { camImage, detailList, section, sectionDue, title } = data;
  return (
    <section className="section dettaglio-prodotto wrapper-container">
      <div className="container-fluid">
        {title?.value && (
          <div className="row">
            <div className="col-12">
              <h1 className="section__title text-center">{title.value}</h1>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-12">
            <div className="dettaglio-prodotto__main">
              <button className="dettaglio-prodotto__btnTitle" onClick={() => setOpen(!open)}>
                <span
                  className={classNames("dettaglio-prodotto__icon", {
                    "dettaglio-prodotto__icon--open": open,
                  })}
                />
                <span>{detailList?.value}</span>
              </button>
              <div
                className={classNames("dettaglio-prodotto__content", {
                  "dettaglio-prodotto__content--open": open,
                })}
              >
                <div className="row">
                  <div className="col-md-7">
                    <div className="dettaglio-prodotto__imgContainer d-md-none">
                      {camImage?.node && (
                        <GatsbyImage
                          className="dettaglio-prodotto__img"
                          image={getImage(camImage.node)}
                          alt={title?.value}
                        />
                      )}
                    </div>
                    {section?.map((item, i) => (
                      <div className="dettaglio-prodotto__item" key={i}>
                        <div className="dettaglio-prodotto__title">{item?.value}</div>
                        <div
                          className="dettaglio-prodotto__detail"
                          dangerouslySetInnerHTML={{
                            __html: item?.content?.sectionDescription?.value,
                          }}
                        ></div>
                      </div>
                    ))}
                  </div>
                  <div className="col-md-5">
                    {sectionDue?.map((item, i) => (
                      <div className="dettaglio-prodotto__item" key={i}>
                        <div className="dettaglio-prodotto__title">{item?.value}</div>
                        <div
                          className="dettaglio-prodotto__detail"
                          dangerouslySetInnerHTML={{
                            __html: item?.content?.sectionDueDescription?.value,
                          }}
                        ></div>
                      </div>
                    ))}
                    <div className="dettaglio-prodotto__imgContainer d-none d-md-block">
                      {camImage?.node && (
                        <GatsbyImage
                          className="dettaglio-prodotto__img"
                          image={getImage(camImage.node)}
                          alt={title?.value}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withPreview(DettaglioProdotto);
export const fragment = graphql`
  fragment DettaglioProdottoFragment on LiferayDettaglioProdotto {
    liferayFields {
      siteId
      articleId
    }
    title {
      value
    }
    detailList {
      value
      content {
        detail {
          value
          content {
            icon {
              node {
                publicURL
              }
            }
            detailDescription {
              value
            }
          }
        }
      }
    }
    section {
      value
      content {
        sectionDescription {
          value
        }
      }
    }
    sectionDue {
      value
      content {
        sectionDueDescription {
          value
        }
      }
    }
    camImage {
      node {
        gatsbyImageData(width: 800)
      }
    }
  }
`;
